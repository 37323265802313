var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ mounted: _vm.isMounted }},[_c('div',{staticClass:"hero-section flex-column justify-center d-flex pb-16"},[_c('div',{staticClass:"shrink"},[_c('h1',{staticClass:"text-h4 text-md-h1 white--text text-center hero-title delay-0",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.hero.title')))}}),_c('div',{staticClass:"\n          text-body-2 text-md-body-1\n          white--text\n          text-container\n          font-weight-bold\n          pb-16\n          pt-0 pt-md-10\n          delay-2\n        ",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.hero.text')))}})])]),_c('div',{staticClass:"primary_light pb-10"},[_vm._m(0),_c('h2',{staticClass:"display-3 primary--text text-center pb-4",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.about.title')))}}),_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"text-body-2 text-md-body-1",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.about.text')))}})])]),_c('gallery',{staticClass:"pt-6 pt-md-10"}),_c('div',{staticClass:"py-10"},[_c('h2',{staticClass:"display-3 primary--text text-center pb-4",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.location.title')))}}),_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"text-body-2 text-md-body-1 font-weight-bold pb-6",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.location.gps')))}}),_c('div',{staticClass:"text-body-2 text-md-body-1",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('home.location.text')))}})])]),_c('gmap-map',{staticStyle:{"width":"100%","height":"378px"},attrs:{"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
    },"center":{ lat: 50.052457113789245, lng: 14.093916706556 },"zoom":10,"map-type-id":"roadmap"}},[_c('gmap-marker',{attrs:{"position":{
        lat: 50.052457113789245,
        lng: 14.093916706556,
      }}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-tabs moveup-5"},[_c('div',{staticClass:"product-tabs--container"},[_c('div',{staticClass:"d-flex mx-auto justify-center"},[_c('div',{staticClass:"\n              flex\n              product-tabs--item\n              white\n              shrink\n              ma-2 ma-md-4\n              pa-4 pa-md-8\n              d-flex\n            "},[_c('div',{staticClass:"card-glamping flex"})]),_c('div',{staticClass:"\n              flex\n              product-tabs--item\n              white\n              shrink\n              ma-2 ma-md-4\n              pa-4 pa-md-8\n              d-flex\n            "},[_c('div',{staticClass:"card-ranch flex"})]),_c('div',{staticClass:"\n              flex\n              product-tabs--item\n              white\n              shrink\n              ma-2 ma-md-4\n              pa-4 pa-md-8\n              d-flex\n            "},[_c('div',{staticClass:"card-permacultura flex"})])])])])}]

export { render, staticRenderFns }