

























































































































import { Component, Vue } from "vue-property-decorator";
import Gallery from "@/components/Gallery.vue";

@Component({
  components: { Gallery },
})
export default class Home extends Vue {
  isMounted = false;

  mounted(): void {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }
}
